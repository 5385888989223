export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: "登录"
    }
  },
  {
    path: "/follow",
    name: "Follow",
    component: () => import("@/views/task/follow.vue"),
    meta: {
      auth: true,
      title: "续保",
    },
  },
  {
    path: "/shortDetail",
    name: "ShortDetail",
    component: () => import("@/views/shortPush/shortDetail.vue"),
    meta: {
      auth: true,
      title: "短推",
    },
  },
  {
    path: "/warReport",
    name: "WarReport",
    component: () => import("@/views/warReport/index.vue"),
    meta: {
      auth: true,
      title: "用心保统计",
    },
  },
  {
    path: "/payPage",
    name: "PayPage",
    component: () => import("@/views/pay/payPage.vue"),
    meta: {
      title: "支付"
    }
  }
];
