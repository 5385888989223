<template>
    <div class="tabBar">
        <div class="menu">
            <div v-if="currentMenus && currentMenus.includes('/mpRenewalTask')">
                <router-link to="/mpRenewalTask">
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/task_active.png')"
                    v-if="currentUrl === '/mpRenewalTask'"
                />
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/task.png')"
                    v-else
                />
                <span>任务</span>
                </router-link>
            </div>
            <div v-if="currentMenus && currentMenus.includes('/mpArriveVehicleRecord')">
                <router-link to="/mpArriveVehicleRecord">
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/approach_active.png')"
                    v-if="currentUrl === '/mpArriveVehicleRecord'"
                />
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/approach.png')"
                    v-else
                />
                <span>进场</span>
                </router-link>
            </div>
            <div v-if="currentMenus && currentMenus.includes('/miniprogram/quote/enter')">
                <router-link to="/miniprogram/quote/enter">
                    <van-image
                        width="25"
                        height="25"
                        :src="require('@/assets/images/menu/quote_active.png')"
                        v-if="currentUrl === '/miniprogram/quote/enter'"
                    />
                    <van-image
                        width="25"
                        height="25"
                        :src="require('@/assets/images/menu/quote.png')"
                        v-else
                    />
                    <span>报价</span>
                </router-link>
            </div>
            <div v-if="currentMenus && currentMenus.includes('/mpRepairSmsList')">
                <router-link to="/mpRepairSmsList">
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/short_active.png')"
                    v-if="currentUrl === '/mpRepairSmsList'"
                />
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/short.png')"
                    v-else
                />
                <span>短推</span>
                </router-link>
            </div>
            <div v-if="currentMenus && currentMenus.includes('/warReport')">
                <router-link to="/warReport">
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/zhanbao_active.png')"
                    v-if="currentUrl === '/warReport'"
                />
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/zhanbao.png')"
                    v-else
                />
                <span>统计</span>
                </router-link>
            </div>
            <div>
                <router-link to="/mine">
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/mine_active.png')"
                    v-if="currentUrl === '/mine'"
                />
                <van-image
                    width="25"
                    height="25"
                    :src="require('@/assets/images/menu/mine.png')"
                    v-else
                />
                <span>我的</span>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import store from '@/store'
import { request } from "@/api/service";
import { authorizeLogin, isWxAuthorize, authorize } from '@/utils/weixin'
export default {
    data () {
        return {
            // currentMenus: []
        }
    },
    computed: {
        ...mapState("user", ["currentMenus"]),
        currentUrl () {
            return this.$route.path
        }
    },
    created () {
        store.dispatch('user/load')
        const routerPath = this.$route.path;
        if (routerPath === "/" && this.currentMenus && this.currentMenus.length>0) {
            this.$router.replace({
                path: this.currentMenus[0],
                query: this.$route.query,
                params: this.$route.params
            });
        }else if(!this.currentMenus || this.currentMenus.length<1){
            this.$router.replace({
                path: "/mine"
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.menu {
  height: 50px;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 10px;
  div {
    display: inline-block;
    font-size: 12px;
    color: #444444;
    width: 50px;
    text-align: center;
    a {
      color: #444444;
    }
    span {
        display: block;
    }
  }
}
</style>
