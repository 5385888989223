import { request } from "@/api/service";
import store from "@/store";
import router from "@/router";
import { Notify } from "vant";
import { mergeWith, cloneDeep, isArray } from "lodash";
export default function getUserInfo() {
  // 获取菜单
  request({
    url: "/afis-auth/auth/user/role/moduleByWX",
    method: "get",
    data: {},
  }).then((res) => {
    let currentMenus = [];
    if( !isArray(res) || res.length<=0){
      store.dispatch("user/setCurrentMenus", currentMenus);
      Notify({ type: "danger", message: "此用户无功能权限" });
      return false
    }
    res && res.map((item) => {
      currentMenus.push(item.path);
    });
    store.dispatch("user/setCurrentMenus", currentMenus);
    router.push({
      path: currentMenus[0],
    });
  });
  // 获取头像 /auth/authAppUser/info
  request({
    url: "/afis-auth/auth/authAppUser/info",
    method: "get",
    data: {},
  }).then((res) => {
    const userInfo = store.getters.info;
    userInfo.headImgUrl = res.headImgUrl;
    store.dispatch("user/set", userInfo);
    //下面的代码废弃
    localStorage.headImgUrl = res.headImgUrl;
  });
  // 获取用户名称
  request({
    url: "/afis-auth/auth/user/current",
    method: "get",
    data: {},
  }).then((res) => {
    const userInfo = store.getters.info;
    mergeWith(userInfo, res);
    store.dispatch("user/set", userInfo);
    //下面的代码废弃
    // localStorage.orgName = res.orgName;
    // localStorage.username = res.username;
    // localStorage.alias = res.alias;
    store.dispatch("user/setOps", res.ops);
  });
}
