export default [
    {
        path: '/product/order/index',
        name: 'ProductOrderIndex',
        component: () => import('@/views/product/order/index.vue')
    },
    {
        path: '/product/order/list',
        name: 'ProductOrderList',
        component: () => import('@/views/product/order/list.vue')
    },
    {
        path: '/product/order/brandCodeSelect',
        name: 'BrandCodeSelect',
        component: () => import('@/views/product/order/brandCodeSelect.vue')
    }
]