const getters = {
  dict: state => state.dict.dictMap,
  info: state => state.user.info,
  productForm: state => state.product.form,
  productParam: state => state.product.param,
  quote: (state) => state.car.quote,
  currentMenus: state => state.user.currentMenus,
  ops: state => state.user.ops,
  cardImg: state => state.car.cardImg
}
export default getters
