import Vue from "vue";
import VueRouter from "vue-router";
import Menu from "./modules/menu";
import Product from "./modules/product";
import Miniprogram from "./modules/miniprogram";
import Common from "./modules/common";
import { isWxAuthorize, authorize, authorizeLogin } from "@/utils/weixin";
import store from "@/store";
import { request } from "@/api/service";
import { Dialog } from "vant";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [...Menu, ...Product, ...Miniprogram, ...Common];

const router = new VueRouter({
  routes,
});
// mpRenewalTask
router.beforeEach(async (to, from, next) => {
  // 判断任务详情展示
  const autoFlag = to.meta && to.meta.auth;
  // const menuFlag = to.meta && to.meta.menu;
  // if ((to.path === '/mpRenewalTask' || to.path === '/follow') && (JSON.parse(localStorage.currentMenus).length > 0 && !JSON.parse(localStorage.currentMenus).includes('/mpRenewalTask'))) {
  //   // authorize("#"+ to.fullPath)
  //   Dialog({ message: '当前用户暂时没有该页面权限' });
  //   next('/mpArriveVehicleRecord')
  //   return false
  // }
  // store.dispatch("user/setCurrentUrl", to.path);

  // if (menuFlag) {
  //   store.dispatch("user/setMenuDis", true);
  // } else {
  //   store.dispatch("user/setMenuDis", false);
  // }
  const autoLogin = autoFlag && (await isWxAuthorize());
  if (autoFlag && autoLogin === null) {
    next(false);
    return false
  }
  if (autoFlag && !autoLogin) {
    authorize("#" + to.fullPath);
    next(false);
  } else {
    document.title = (to.meta && to.meta.title) || "用心保";
    next();
  }
});
export default router;
