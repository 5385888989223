import Layout from "@/views/menu/index";
const meta = {
  auth: true,
};
export default [
  {
    path: "/",
    name: "Menu",
    component: Layout,
    meta: {
      ...meta,
      title: "用心保",
    },
    children: [
      {
        path: "/mpRenewalTask",
        name: "MpRenewalTask",
        component: () => import("@/views/task/index.vue"),
        meta: {
          ...meta,
          title: "续保",
        },
      },
      {
        path: "/mpArriveVehicleRecord",
        name: "MpArriveVehicleRecord",
        component: () => import("@/views/approach/index.vue"),
        meta: {
          ...meta,
          title: "进场",
        },
      },
      {
        path: "/mpRepairSmsList",
        name: "MpRepairSmsList",
        component: () => import("@/views/shortPush/index.vue"),
        meta: {
          ...meta,
          title: "短推",
        },
      },
      {
        path: "/miniprogram/quote/enter",
        name: "QuoteEnter",
        component: () => import("@/views/miniprogram/quote/enter/index.vue"),
        meta: {
          ...meta,
          title: "车险报价",
        },
      },
      {
        path: "/mine",
        name: "Mine",
        component: () => import("@/views/mine/index.vue"),
        meta: {
          ...meta,
          title: "我的",
        },
      },
      {
        path: "/warReport",
        name: "WarReport",
        component: () => import("@/views/warReport/index.vue"),
        meta: {
          ...meta,
          title: "用心保统计",
        },
      },
    ],
  },
];
