<template>
  <div>
    <router-view/>
    <TabBar v-show="hideshow" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import TabBar from './tabBar.vue'
export default {
  components: { TabBar },
  data () {
    return {
      docmHeight: window.innerHeight,  //默认屏幕高度
      showHeight: window.innerHeight,   //实时屏幕高度
      hideshow:true,  //显示或者隐藏footer
    }
  },
  computed: {
  },

  watch:{
    showHeight: function(newValue) {
      if(this.docmHeight > newValue){
        this.hideshow = false
      }else{
        this.hideshow = true
      }
    }
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = ()=>{
      return(()=>{
        this.showHeight = window.innerHeight;
      })()
    }
  }

}
</script>
<style lang="scss" scoped>
</style>