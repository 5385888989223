import Vue from "vue";
import Vuex from "vuex";

import dict from "./modules/dict";
import user from "./modules/user";
import product from "./modules/product";
import car from "./modules/car";
import getters from "./getters";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    dict,
    user,
    product,
    car,
  },
  getters,
});
