export default {
  namespaced: true,
  state: {
    // 用户信息
    quote:
      (localStorage.getItem("afis-wx-car_quote") &&
        JSON.parse(localStorage.getItem("afis-wx-car_quote"))) ||
      {},
    quoteRes:
      (localStorage.getItem("afis-wx-car_quoteRes") &&
        JSON.parse(localStorage.getItem("afis-wx-car_quoteRes"))) ||
      {},
    holderInfo: (localStorage.getItem("afis-wx-car_holderInfo") &&
      JSON.parse(localStorage.getItem("afis-wx-car_holderInfo"))) ||
    {},
    insuredInfo: (localStorage.getItem("afis-wx-car_insuredInfo") &&
      JSON.parse(localStorage.getItem("afis-wx-car_insuredInfo"))) ||
    {},
    cardImg: {
      vehicle: {
        vehiclelicense: '',
        vehicleInvoice: '',
        vehicleCertificate: ''
      },
      owner: {},
      holder: {},
      insured: {}
    }
  },
  actions: {
    /**
     * @description 设置询价信息
     * @param {Object} context
     * @param {*} quote quote
     */
    async setQuote({ state, dispatch }, quote) {
      state.quote = quote;
      localStorage.setItem("afis-wx-car_quote", JSON.stringify(quote));
    },
    /**
     * @description 设置询价信息
     * @param {Object} context
     * @param {*} quote quote
     */
    async setQuoteRes({ state, dispatch }, quoteRes) {
      // store 赋值
      state.quoteRes = quoteRes;
      localStorage.setItem("afis-wx-car_quoteRes", JSON.stringify(quoteRes));
    },
    async setHolderInfo({ state, dispatch }, holderInfo) {
      // store 赋值
      state.holderInfo = holderInfo;
      localStorage.setItem("afis-wx-car_holderInfo", JSON.stringify(holderInfo));
    },
    async setInsuredInfo({ state, dispatch }, insuredInfo) {
      // store 赋值
      state.insuredInfo = insuredInfo;
      localStorage.setItem("afis-wx-car_insuredInfo", JSON.stringify(insuredInfo));
    },
    async setCardImg({ state, dispatch }, cardImg) {
      // store 赋值
      state.cardImg = cardImg;
    }
  },
};
