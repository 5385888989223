<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// import { authorizeLogin } from '@/utils/weixin'
// authorizeLogin()
if (process.env.NODE_ENV !== "production") {
  var vConsole = new VConsole();
}
import { mapState } from "vuex";
import TabBar from '@/components/tabBar.vue'
export default {
}
</script>
<style lang="scss" scoped>
// #app {
//   background: rgb(239, 239, 249);
// }
</style>