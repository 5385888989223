import store from "@/store";
import { wxlogin } from "@/api/common/user";
import { Dialog } from "vant";
import router from "@/router";
import getUserInfo from "@/api/common/getUserInfo";
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const url = window.location.href.split("#")[0];
  const search = url.split("?")[1];
  if (search) {
    var r = search.substr(0).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
  } else {
    return null;
  }
}
// router.push({
//   path: '/task'
// })
export function authorize(path) {
  store.dispatch("user/set", null);
  store.dispatch("user/setCurrentMenus", []);
  // return false
  const link =
    window.location.origin + "/" + (path ? path : window.location.hash);
  const appid = process.env.VUE_APP_WX_APPID; // 修改为你的appid
  // 1.使用encodeURIComponent以及JSON.stringify()方法对对象进行字符串化和编码，这样可以控制url参数的长度，参考示例代码（uni-app书写方式，微信小程序自己改。）
  const uri = encodeURIComponent(link);
  // 2.接受信息的页面使用JSON.parse()以及decodeURIComponent()接收和解码参数。
  // snsapi_base   //snsapi_base    scope = snsapi_base（不弹出授权页面，直接跳转，只能获取用户 openid ）。
  // snsapi_userinfo  弹出
  const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${uri}&response_type=code&state=wxAuth&scope=snsapi_base&state=#wechat_redirect`;
  window.location.href = authURL;
}
export async function isWxAuthorize() {
  if (store.getters.info) {
    // 已经授权的不需要再授权
    return true;
  }
  const state = getUrlParam("state");
  const params = getUrlParam("code"); // 地址解析
  if (state === "wxAuth" && params) {
    try {
      const res = await wxlogin(params);
      if (!res.token && res.thirdId) {
        router.push({
          path: "/login?thirdId=" + res.thirdId,
        });
        return true;
      }
      if (res) {
        store.dispatch("user/set", res);
        // store.dispatch("dict/setDict");
      }
      getUserInfo();
      return true;
    } catch (e) {
      Dialog.confirm({
        title: "授权失败",
        message: "授权失败,是否重新授权",
        confirmButtonText: "重新授权",
      })
        .then(() => {
          authorize();
        })
        .catch(() => {});
      return null;
    }
  } else {
    return false;
  }
}
export function authorizeLogin() {
  // if (store.getters.info) {
  //   // 已经授权的不需要再授权
  //   return true
  // }
  store.dispatch("user/set", null);
  store.dispatch("user/setCurrentMenus", ["1"]);
  const state = getUrlParam("state");
  const params = getUrlParam("code"); // 地址解析
  if (state === "wxAuth" && params) {
    wxlogin(params)
      .then((res) => {
        if (!res.token && res.thirdId) {
          router.push({
            path: "/login?thirdId=" + res.thirdId,
          });
          return false;
        }
        let obj = store.getters.info;
        obj.token = res.token;
        obj.thirdId = res.thirdId;
        store.dispatch("user/set", obj);
        // store.dispatch("dict/setDict");
        getUserInfo();
      })
      .catch((error) => {
        Dialog.confirm({
          title: "授权失败",
          message: "授权失败,是否重新授权",
          confirmButtonText: "重新授权",
        })
          .then(() => {
            authorize();
          })
          .catch(() => {});
      });
  } else {
    authorize();
  }
}
