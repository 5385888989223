export default {
  namespaced: true,
  state: {
    // 用户信息
    info:
      localStorage.getItem("afis-wx-user_info") &&
      JSON.parse(localStorage.getItem("afis-wx-user_info")),
    disMenu: true,
    currentUrl: "",
    currentMenus: [],
    ops: [],
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    async set({ state, dispatch }, info) {
      // store 赋值
      state.info = info;
      localStorage.setItem("afis-wx-user_info", JSON.stringify(info));
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    async load({ state, dispatch }) {
      // store 赋值
      state.info = JSON.parse(localStorage.getItem("afis-wx-user_info"));
      state.currentMenus = JSON.parse(
        localStorage.getItem("afis-wx-user_currentMenus")
      );
      state.ops = JSON.parse(localStorage.getItem("ops"));
    },
    async setMenuDis({ state, dispatch }, disMenu) {
      // store 赋值
      state.disMenu = disMenu;
      localStorage.setItem("disMenu", JSON.stringify(disMenu));
    },
    async setCurrentUrl({ state, dispatch }, currentUrl) {
      // store 赋值
      state.currentUrl = currentUrl;
      localStorage.setItem("currentUrl", JSON.stringify(currentUrl));
    },
    async setCurrentMenus({ state, dispatch }, currentMenus) {
      // store 赋值
      state.currentMenus = currentMenus;
      localStorage.setItem(
        "afis-wx-user_currentMenus",
        JSON.stringify(currentMenus)
      );
    },
    async setOps({ state, dispatch }, ops) {
      // store 赋值
      state.ops = ops;
      localStorage.setItem("ops", JSON.stringify(ops));
    },
  },
};
