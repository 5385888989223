import { queryDictContentListAll } from "@/api/common/dict";
const dictMapParam = {
  CS_COVERAGE_SCOPE: [],
  CS_COVERAGE_PERIOD: [],
  CS_COVERRAGE_TYPE: [],
  CS_PAY_STATUS: [],
  CS_ORDER_STATUS: [],
  WHEATHER_STATUS: [
    {
      label: "未知",
      value: null,
    },
    {
      label: "是",
      value: 1,
    },
    {
      label: "否",
      value: 0,
    },
  ],
  jkeiorhdgk: [],
  OWNER_TYPE: [],
  PLATE_FLAG: [],
  VALID_STATUS: [],
  DEPT_STATUS1: [],
  VEHICLE_KIND: [],
  MARITAL_STATUS: [],
  CREATE_SOURCE: [],
  DEPT_STATUS: [],
  POLICY_PROPERTY: [],
  MSG_SEND_TYPE: [],
  RENEWAL_QUERY_FLAG: [],
  ORG_TYPE: [],
  TENANT_TYPE: [],
  POLICY_SOURCE: [],
  TASK_TYPE: [],
  EMP_FILE_TYPE: [],
  RPT_URL_CFG: [],
  CHANNEL_TYPE: [],
  CLASS_TYPE: [],
  CUST_PROPERTY: [],
  BUSI_DUE_STATUS: [],
  TENANT_MODE: [],
  BIZ_TYPE: [],
  CUST_TYPE: [],
  INS_COMPANY_TYPE: [],
  TRACK_INTENT: [],
  TEAM_LEVLE: [],
  INS_COMPANY_LVFORM: [],
  REG_METHOD: [],
  CERTIFICATE_TYPE_PUB: [],
  ALLOCATION_STATUS: [],
  MSG_BUSI_TYPE: [],
  INIT_FLAG: [],
  PROFESSIONAL_LEVEL: [],
  CUSTOMER_TYPE: [],
  SEX: [],
  CUST_CERTIFICATE_TYPE: [],
  APPROVAL_STATUS: [],
  START_FORBID: [],
  TENANT_LEVEL: [],
  INS_COMPANY_LEVEL: [],
  OPERATOR_TYPE: [],
  COMMON_STATUS: [],
  RPT_TYPE: [],
  TEST_PROJECT: [],
  MSG_MESSAGE_STATUS: [],
  BUSINESS_TYPE: [],
  CATEGORY: [],
  POLITICS_STATUS: [],
  WPORK_STATUS: [],
  GENDER: [],
  CUST_FLAG: [],
  POLICY_PRODUCT_TYPE: [],
  CERTIFICATE_TYPE_PER: [],
  MSG_CONTENT_TYPE: [],
  VEHICLE_USAGE: [],
  REPAIR_SMS_TRACK_STATUS: [],
  OPERATING_STATE: [],
  RENEWAL_CUS_STATE: [],
  CERT_TYPE: [],
  LOSS_REASON: [],
  CERT_LONG_FLAG: [],
  VEHICLE_DRIVE_AREA: [],
  START_STOP_STATUS: [],
  GENERATE_NEXT_YEAR_FLAG: [],
  CUST_GENDER: [],
  INSURED_TYPE: [],
  SYS_RECEIVE_TYPE: [],
  COMMON_IF: [],
  ETHNIC_GROUP: [],
  COMPANY_NATURE: [],
  CONTRACT_TYPE: [],
  ROLE_TYPE: [],
  FINANCE_ORG_TYPE: [],
  PRIORITY: [],
  AUTO_CERTIFICATE_TYPE_PUB: [],
  AUTO_CERTIFICATE_TYPE_PER: [],
  FRAME_NO_ACTIVATE: [],
  RENEWAL_TASK_QUERY_SCOPE: [],
  LOSS_REASON: [],
  ARRIVE_VEHICLE_TASK_QUERY_SCOPE: [],
  INS_COMPANY: [
    {
      label: '人保',
      value: 'PICC'
    },
    {
      label: '太保',
      value: 'CPIC'
    },
    {
      label: '中国平安',
      value: 'PAIC'
    },
    {
      label: '中华联合',
      value: 'CICP'
    },
    {
      label: '阳光保险',
      value: 'YGBX'
    },
    {
      label: '中国太平',
      value: 'TPIC'
    },
    {
      label: '国寿财',
      value: 'GPIC'
    },
    {
      label: '大地财险',
      value: 'CCIC'
    },
    {
      label: '中银财险',
      value: 'BOCI'
    }
  ]
};
export default {
  namespaced: true,
  state: {
    // 字典数据源
    dictSource: [],
    // 字典数据对象
    dictMap: dictMapParam,
  },
  actions: {
    async setDict({ state, dispatch }) {
      // 字典初始化
      // 初始化dictMap
    //   console.log(key, value)
      const dictMap = await queryDictContentListAll("afis-agency");
      let dictData = Object.assign(state.dictMap, dictMap);
      for (let i in dictData) {
        if (i === "REPAIR_SMS_TRACK_STATUS") {
          // dictData[i].unshift({
          //   id: 'ALL',
          //   text: '全部',
          //   value: 'ALL',
          //   label: '全部'
          // })
        }
        dictData[i].map((item) => {
          item.text = item.label;
        });
      }
      state.dictMap = dictData;
      localStorage.setItem(
        "afis-wx-dict_dictMap",
        JSON.stringify(state.dictMap)
      );
    },
    async loadDict({ state, dispatch }) {
      // 字典初始化
      // 初始化dictMap
      if (!localStorage.getItem("afis-wx-dict_dictMap")) {
        const dictMap = await queryDictContentListAll("afis-agency");
        let dictData = Object.assign(state.dictMap, dictMap);
        for (let i in dictData) {
          if (i === "REPAIR_SMS_TRACK_STATUS") {
            // dictData[i].unshift({
            //   id: 'ALL',
            //   text: '全部',
            //   value: 'ALL',
            //   label: '全部'
            // })
          }
          dictData[i].map((item) => {
            item.text = item.label;
          });
        }
        state.dictMap = dictData;
        localStorage.setItem(
          "afis-wx-dict_dictMap",
          JSON.stringify(state.dictMap)
        );
      } else {
        state.dictMap = Object.assign(
          state.dictMap,
          JSON.parse(localStorage.getItem("afis-wx-dict_dictMap"))
        );
      }
    },
  },
};
