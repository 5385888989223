export default {
  namespaced: true,
  state: {
    // 用户信息
    form:
      (localStorage.getItem("afis-wx-product_form") &&
        JSON.parse(localStorage.getItem("afis-wx-product_form"))) ||
      {},
    param: {},
  },
  actions: {
    async setForm({ state }, { productCode, form }) {
      state.form[productCode] = form;
      localStorage.setItem("afis-wx-product_form", JSON.stringify(state.form));
    },
    async loadForm({ state }) {
      state.form = JSON.parse(localStorage.getItem("afis-wx-product_form"));
    },
    async setParam({ state }, { productCode, param }) {
      state.param[productCode] = param;
      localStorage.setItem(
        "afis-wx-product_param",
        JSON.stringify(state.param)
      );
    },
    async loadParam({ state }) {
      state.info = JSON.parse(localStorage.getItem("afis-wx-product_param"));
    },
  },
};
