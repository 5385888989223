export default [
  {
    path: "/miniprogram/quote/list",
    name: "QuoteList",
    component: () => import("@/views/miniprogram/quote/list/index.vue"),
    meta: {
      auth: true,
      title: "投保列表",
    },
  },
  {
    path: "/miniprogram/quote/detail",
    name: "QuoteDetail",
    component: () => import("@/views/miniprogram/quote/list/detail.vue"),
    meta: {
      auth: true,
      title: "详情信息",
    },
  },
  {
    path: "/miniprogram/quote/simple",
    name: "QuoteSimple",
    component: () => import("@/views/miniprogram/quote/simple/index.vue"),
    meta: {
      auth: true,
      title: "车险报价",
    },
  },
  {
    path: "/miniprogram/quote/simple/vehicle",
    name: "vehicleModel",
    component: () => import("@/views/miniprogram/quote/simple/vehicle.vue"),
    meta: {
      auth: true,
      title: "车辆信息",
    },
  },
  {
    path: "/miniprogram/quote/simple/owner",
    name: "ownerModel",
    component: () => import("@/views/miniprogram/quote/simple/owner.vue"),
    meta: {
      auth: true,
      title: "车主信息",
    },
  },
  {
    path: "/miniprogram/quote/simple/holder",
    name: "holderModel",
    component: () => import("@/views/miniprogram/quote/simple/holder.vue"),
    meta: {
      auth: true,
      title: "投保人信息",
    },
  },
  {
    path: "/miniprogram/quote/simple/insured",
    name: "insuredModel",
    component: () => import("@/views/miniprogram/quote/simple/insured.vue"),
    meta: {
      auth: true,
      title: "被保人信息",
    },
  },
  {
    path: "/miniprogram/quote/simple/risk",
    name: "riskModel",
    component: () => import("@/views/miniprogram/quote/simple/risk.vue"),
    meta: {
      auth: true,
      title: "险种信息",
    },
  },
  {
    path: "/miniprogram/quote/normal/base",
    name: "QuoteBaseNormal",
    component: () => import("@/views/miniprogram/quote/normal/base.vue"),
    meta: {
      auth: true,
      title: "基本信息",
    },
  },
  {
    path: "/miniprogram/quote/normal/risk",
    name: "QuoteRiskNormal",
    component: () => import("@/views/miniprogram/quote/normal/risk.vue"),
    meta: {
      auth: true,
      title: "险种信息",
    },
  },
  {
    path: "/miniprogram/quote/result",
    name: "QuoteResult",
    component: () => import("@/views/miniprogram/quote/result/index.vue"),
    meta: {
      auth: true,
      title: "报价结果",
    },
  },
  {
    path: "/miniprogram/quote/underwriting",
    name: "Underwriting",
    component: () => import("@/views/miniprogram/quote/underwriting/index.vue"),
    meta: {
      auth: true,
      title: "核保结果",
    },
  },
  {
    path: "/miniprogram/quote/cardCollection",
    name: "CardCollection",
    component: () => import("@/views/miniprogram/quote/cardCollection/index.vue"),
    meta: {
      auth: true,
      title: "身份证采集",
    },
  },
  {
    path: "/miniprogram/quote/cardCollection",
    name: "CardCollection",
    component: () => import("@/views/miniprogram/quote/cardCollection/index.vue"),
    meta: {
      auth: true,
      title: "身份证采集",
    },
  },
  {
    path: "/miniprogram/quote/cardCollection/holder",
    name: "CollectionHolder",
    component: () => import("@/views/miniprogram/quote/cardCollection/holder.vue"),
    meta: {
      auth: true,
      title: "投保人信息",
    },
  },
  {
    path: "/miniprogram/quote/cardCollection/insured",
    name: "CollectionInsured",
    component: () => import("@/views/miniprogram/quote/cardCollection/insured.vue"),
    meta: {
      auth: true,
      title: "被保人信息",
    },
  },
  {
    path: "/miniprogram/quote/updateCode",
    name: "UpdateCode",
    component: () => import("@/views/miniprogram/quote/underwriting/updateCode.vue"),
    meta: {
      auth: true,
      title: "更新验证码",
    },
  },
  {
    path: "/miniprogram/quote/sendCode",
    name: "SendCode",
    component: () => import("@/views/miniprogram/quote/underwriting/sendCode.vue"),
    meta: {
      auth: true,
      title: "发送验证码",
    },
  },
  {
    path: "/miniprogram/quote/uploadFile",
    name: "UploadFile",
    component: () => import("@/views/miniprogram/quote/underwriting/uploadFile.vue"),
    meta: {
      auth: true,
      title: "上传资料",
    },
  },
  {
    path: "/miniprogram/quote/pay",
    name: "Pay",
    component: () => import("@/views/miniprogram/quote/underwriting/pay.vue"),
    meta: {
      auth: true,
      title: "支付明细",
    },
  }
];
